import IntroJs from 'intro.js'

class BROnboarding {
  constructor (name, component) {
    this.name = name
    this.component = component
    this.introJs = new IntroJs()
    this._setBehavior()
  }

  buscarSteps (url) {
    window
      .fetch(url)
      .then(response => response.json())
      .then(data => (this.stepsjson = data))
      .catch(console.error)
  }

  _setBehavior () {
    if (this.component) {
      if (
        this.component.getAttribute('data-steps') !== undefined &&
        this.component.getAttribute('data-steps') !== null
      ) {
        this.steps = this.component.getAttribute('data-steps')
        this.buscarSteps(this.component.getAttribute('data-steps'))
      }
      this.component.addEventListener('click', event => {
        this.start(this.stepsjson)
      })
    }
  }

  introJsConfig () {
    const introjsTooltip = window.document.querySelector('.introjs-tooltip')
    const introjsTooltipSkip = introjsTooltip.querySelector(
      '.introjs-skipbutton'
    )

    const introjsSkip = document.createElement('div')
    const introjsSkipButton = document.createElement('button')

    introjsSkipButton.textContent = introjsTooltipSkip.text
    introjsSkipButton.classList.add('br-button', 'small')
    introjsSkipButton.addEventListener('click', () => IntroJs().exit())

    introjsSkip.classList.add('introjs-skip')
    introjsSkip.appendChild(introjsSkipButton)

    introjsTooltip.querySelector('.introjs-tooltipbuttons').before(introjsSkip)
  }

  start (steps) {
    this.introJs
      .setOptions({
        prevLabel: '<i class="fas fa-angle-left" aria-hidden="true"></i>',
        nextLabel: '<i class="fas fa-angle-right" aria-hidden="true"></i>',
        skipLabel: 'Pular Tutorial',
        doneLabel: 'Fechar',
        steps: steps
      })
      .onafterchange(targetElement => {
        if (document.querySelector('.introjs-skip')) {
        } else {
          new BROnboarding().introJsConfig()
        }
      })
      .start()
  }
}

const bronboardinglist = []
for (const brOnBoarding of window.document.querySelectorAll(
  '[data-trigger="onboarding"]'
)) {
  bronboardinglist.push(new BROnboarding('br-boarding', brOnBoarding))
}

export default BROnboarding
