class BRTemplateBase {
  constructor (name, component) {
    this.name = name
    this.component = component
    this._setBehavior()
  }

  _setBehavior () {}
}

const templateBaseList = []
for (const brTemplateBase of window.document.querySelectorAll(
  '.template-base'
)) {
  templateBaseList.push(new BRTemplateBase('template-base', brTemplateBase))
}

export default BRTemplateBase
